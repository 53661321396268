//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Topic",
  props: {
    topic: {
      type: Object,
      required: true
    }
  }
};