//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    async tableLoader() {
      // { query }
      // const result = await this.$store.dispatch("seo/getQueriesTop", {
      //     ...this.reportSettings,
      //     ...query,
      // });
      const result = this.reportSettings.mp === "wb" ? {
        data: [{
          k_id: 55,
          keyword: "платье женское",
          shows: 2005096,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-16",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 20,
          keyword: "пижама женская",
          shows: 1121617,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-16",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 7,
          keyword: "платье",
          shows: 872295,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-16",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 9546,
          keyword: "гирлянда штора",
          shows: 695641,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-16",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 1684,
          keyword: "гирлянда",
          shows: 683107,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-16",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }],
        total: 10,
        success: true,
        message: "Ok"
      } : {
        data: [{
          k_id: 2566,
          keyword: "платье женское",
          shows: 168280,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-19",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 3489,
          keyword: "наушники беспроводные",
          shows: 162998,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-19",
          kA_date_str: "2022-08-14",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 196669,
          keyword: "гирлянда штора",
          shows: 127156,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-19",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 241162,
          keyword: "пижама женская",
          shows: 117251,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-19",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }, {
          k_id: 3538614,
          keyword: "гирлянда",
          shows: 113253,
          q_suggest: 0,
          q_user: 0,
          date_shows: "2022-12-19",
          kA_date_str: "2022-08-11",
          kA_date_end: "2023-01-17",
          qSkip: 0,
          CH_integrity: 0
        }],
        total: 10,
        success: true,
        message: "Ok"
      };
      return {
        items: result.data,
        total: result.total
      };
    }
  },
  computed: {
    columns() {
      return [{
        title: "Топ 5 поисковых запросов",
        show: true,
        name: "keyword",
        type: "text",
        width: 200,
        filter: "text"
      }, {
        title: `Частотность ${this.reportSettings.mp.toUpperCase()}`,
        show: true,
        name: "shows",
        type: "number",
        width: 200,
        filter: "numberRange"
      }];
    }
  }
};