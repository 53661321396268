//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MonitoringTable from "@/components/Seo/MonitoringTable.vue";
import SeoWrapper from "@/components/Seo/Wrapper.vue";
export default {
  name: "PositionMonitoring",
  components: {
    MonitoringTable,
    SeoWrapper
  },
  // data() {
  //     return {
  //         height: 0,
  //     };
  // },
  created() {
    this.$store.dispatch("seo/setHasProjects");
    this.$store.dispatch("seo/getQueriesStats");
  },
  mounted() {
    // setInterval(() => {
    //     this.matchHeight();
    // }, 300);
  },
  methods: {
    // matchHeight() {
    //     this.height = this.$refs.block.clientHeight;
    // },
  },
  computed: {
    reportSettings() {
      return {};
    }
  }
};