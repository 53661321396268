//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EntitySearch from "@/components/EntitySearch.vue";
import ProductsTable from "@/components/Rating/ProductsTable.vue";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
export default {
  name: "Products",
  props: {
    mp: {
      type: String,
      required: true
    }
  },
  components: {
    EntitySearch,
    ProductsTable
  },
  data() {
    return {
      calendarBoundaries: null
    };
  },
  mounted() {
    this.setCalendarBoundaries();
  },
  methods: {
    searchLoader(query) {
      return this.$store.dispatch("product/getProductByName", {
        query,
        mp: this.mp
      });
    },
    getRoute(item) {
      return {
        name: "ProductInfo",
        params: {
          p_id: item.p_id,
          mp: this.mp
        }
      };
    },
    async setCalendarBoundaries() {
      const dates = await this.$store.dispatch("common/getCalendarDates", {
        mp: this.mp
      });
      this.calendarBoundaries = [dates.date, dates.date2];
    }
  },
  computed: {
    ratingBindings() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      const [date, date2] = dateRangeBoundaries(weekRange, this.calendarBoundaries);
      return {
        action: "rating/getRatingProducts",
        id: this._uid,
        routeParams: {
          mp: this.mp
        },
        reportSettings: {
          mp: this.mp,
          metric: "revenue",
          date,
          date2,
          size: 20
        },
        paginatable: false
      };
    }
  }
};