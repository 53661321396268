//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MyShopsMixin from "@/mixins/MyShops";
import OrdersTable from "@/components/Connections/OrdersTable.vue";
import HasTokens from "@/components/HasTokens.vue";
export default {
  name: "Orders",
  mixins: [MyShopsMixin],
  components: {
    HasTokens,
    OrdersTable
  },
  methods: {},
  computed: {
    reportSettings() {
      const calendar = this.$store.state.connections.calendar;
      const selectedTokens = this.$store.state.connections.selectedTokens;
      if (!calendar) {
        return null;
      }
      const dates = calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      return {
        date: dates[0],
        date2: dates[1],
        tokens: selectedTokens.map(item => item.id),
        page_size: 10
      };
    }
  }
};