//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainTitle from "@/components/MainTitle.vue";
import LinkBlock from "./LinkBlock.vue";
import Video from "./Video.vue";
export default {
  name: "News",
  components: {
    MainTitle,
    LinkBlock,
    Video
  },
  data() {
    return {
      linkBlocks: [{
        title: "Внешняя аналитика маркетплейсов Ozon и Wildberries",
        link: "https://help.salesfinder.ru#rec490132978"
      }, {
        title: "Репрайсер: динамическое ценообразование",
        link: "https://help.salesfinder.ru#rec747387751"
      }, {
        title: "Бесплатное расширение для браузера",
        link: "https://help.salesfinder.ru/salesfinder-plugin"
      }, {
        title: "Мониторинг РРЦ",
        link: "https://help.salesfinder.ru/salesfinder-monitoring-rrc"
      }, {
        title: "Управление отзывами",
        link: "https://help.salesfinder.ru#rec490136094"
      }, {
        title: "Мои магазины: внутренняя аналитика Ozon и WB",
        link: "https://help.salesfinder.ru#rec691810683"
      }, {
        title: "Мои списки",
        link: "https://help.salesfinder.ru/salesfinder-my-lists"
      }, {
        title: "SEO-анализ",
        link: "https://help.salesfinder.ru#rec538055321"
      }, {
        title: "Контроль изменений",
        link: "https://help.salesfinder.ru/salesfinder-events-monitoring"
      }],
      videos: [{
        title: "АНАЛИТИКА ВАЙЛДБЕРРИЗ И ОЗОН. Как SalesFinder помогает продавать больше.",
        link: "https://www.youtube.com/embed/DIhz9LdBfKI?si=rVlDdkAd5eotedy2"
      }, {
        title: "Как ВЫБРАТЬ НИШУ и товар на Wildberries и Ozon.",
        link: "https://www.youtube.com/embed/L6TtLbSuOl8?si=5ghyRkKnU86wfsc_"
      }, {
        title: "Анализ финансов на OZON: простой расчет комиссии, прибыли, логистики и прочих расходов для селлеров",
        link: "https://www.youtube.com/embed/pM1uYQlPy1s?si=_L3W9DNFemAEmF_D"
      }, {
        title: "Управление ценами на Ozon и WB: репрайсер для динамического ценообразования на маркетплейсах",
        link: "https://www.youtube.com/embed/cXrROzh3gJo?si=o3kSEfynfsqCqklW"
      }, {
        title: "Как избежать out of stock на Вайлдберриз и Ozon | Автоматический контроль остатков",
        link: "https://www.youtube.com/embed/LMkE3uSpFG4?si=HEAXPdDnNIf1K6Os"
      }, {
        title: "Настройка уникальных ответов на отзывы Wildberries",
        link: "https://www.youtube.com/embed/NoWJ52Gdvck?si=SjIY2eaN3EL9BMa-"
      }, {
        title: "Расширение (плагин) для браузера Chrome и Яндекс.Браузер.",
        link: "https://www.youtube.com/embed/iBmFDhHluYw?si=DtfI-1Y1hpNKbuX4"
      }, {
        title: "SEO И КЛЮЧЕВЫЕ ЗАПРОСЫ ОЗОН. Как заполнить и повысить продажи.",
        link: "https://www.youtube.com/embed/JeCZAAMqIS4?si=7R-DMaPbgWUK7qz8"
      }]
    };
  }
};