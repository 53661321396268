//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};