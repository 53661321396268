//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppTabs from "@/components/AppTabs.vue";
import Categories from "@/components/Dashboard/ExternalAnalytics/Categories.vue";
import Products from "@/components/Dashboard/ExternalAnalytics/Products.vue";
import Brands from "@/components/Dashboard/ExternalAnalytics/Brands.vue";
import Sellers from "@/components/Dashboard/ExternalAnalytics/Sellers.vue";
const tableComponents = {
  categories: Categories,
  products: Products,
  brands: Brands,
  sellers: Sellers
};
export default {
  name: "ExternalAnalytics",
  components: {
    AppTabs
  },
  props: {
    mp: {
      type: String,
      required: true
    }
  },
  data() {
    const tabs = [{
      id: "categories",
      title: "Категории",
      entity: "category",
      route: "CategoryHome"
    }, {
      id: "products",
      title: "Товары",
      entity: "product",
      route: "ProductHome"
    }, {
      id: "brands",
      title: "Бренды",
      entity: "brand",
      route: "BrandHome"
    }, {
      id: "sellers",
      title: "Продавцы",
      entity: "seller",
      route: "SellerHome"
    }];
    return {
      tabs,
      tabs_active: tabs[0]
    };
  },
  methods: {
    getTableComponent(id) {
      return tableComponents[id];
    }
  },
  computed: {
    tableKey() {
      return `${this.tabs_active.id}`;
    }
  }
};